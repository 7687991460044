import React, { createContext, useState, useEffect, useContext } from 'react'

import toast from '../elem/Toast'
import withConfig from './withConfig'
import { DateContext } from './DateContext'
import { CurrentDashboardContext } from './CurrentDashboardContext'
import { FaChartPie, FaChartBar, FaHashtag, FaRegMap, FaCube, FaClipboardList, FaChartLine, FaTable, FaMap, FaComment, FaImage, FaDollarSign} from 'react-icons/fa'
import { MdTrendingUp } from 'react-icons/md'
import { APIRequestContext } from './APIRequestContext'

const WidgetDataContext = createContext(null)

const WidgetDataContextProvider = ({ config, widget, children }) => {
    const {
        WidgetId: widgetId,
        WidgetName: widgetName,
        WidgetType: widgetType,
        FeatureFiles: featureFiles,
        GroupBy
    } = widget

    const datectx = useContext(DateContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { detailKey, currentWidgetGroup } = useContext(CurrentDashboardContext)
    const [widgetData, setWidgetData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [showLegend, setShowLegend] = useState(true)
    const [ abortController, setAbortController ] = useState(new AbortController())
    const { API_URL } = config // api url configured in public/config.<development|production>.js
    
    // group by
    const groupByOptions = GroupBy ? GroupBy.split('|') : []
    const [activeGroupBy, setGroupBy] = useState(groupByOptions.length ? groupByOptions[0] : null)
    const dateQueryString = datectx ? datectx.dateQueryString : null

    const fetchWidget = () => {
        const abort = new AbortController()
        setAbortController(abort)
        setLoading(true)
        const groupByClause = activeGroupBy
            ? `groupBy=${encodeURIComponent(activeGroupBy)}`
            : ''
        const dateClause = dateQueryString ? `&${dateQueryString}` : ''
        const detailKeyClause = detailKey ? `&detailKey=${detailKey}` : ''
        authenticatedFetch(`${API_URL}/widget/${widgetId}?${groupByClause}${dateClause}${detailKeyClause}`, {signal: abort.signal})
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setWidgetData(response.data)
                setLoading(false)
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    toast({
                        level: 'error',
                        message:
                        'Widget: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                    })
                    setLoading(false)
                }
            })
        }

    // fetch the data associated w/ the widget
    useEffect(() => {
        if (widgetId) {
            if (currentWidgetGroup.DetailKey) {
                // if (detailKey) {
                    abortController.abort()
                    fetchWidget()
                // }
            } else {
                abortController.abort()
                fetchWidget()
            }
        }
    }, [widgetId, API_URL, activeGroupBy, dateQueryString, currentWidgetGroup, detailKey])

    if (!widget) {
        return null
    }

    //setIcon
    const getIconFromWidgetType = (type) => {
        switch (type) {
            case 'Number':
                return <FaHashtag/>
            case 'Financial':
                return <FaDollarSign />
            case 'Grid':
                return <FaTable/>
            case 'BarChart':
                return <FaChartBar/>
            case 'LineChart':
                return <FaChartLine/>
            case 'ScatterChart':
                return <MdTrendingUp />
            case 'ThemeMap':
                return <FaRegMap/>
            case 'PieChart':
                return <FaChartPie/>
            case 'Form':
                return <FaClipboardList/>
            case '2DFeatureMap':
                return <FaMap/>
            case '3DFeatureMap':
                return <FaCube/>
            case '3DCrossSectionMap':
                return <FaCube/>
            case 'Text':
                return <FaComment />
            case 'Image':
                return <FaImage />
            default:
                return 'div'
        }
    }

    const widgetIcon = getIconFromWidgetType(widgetType)

    return (
        <WidgetDataContext.Provider
            value={{
                widgetName,
                widgetType,
                widgetIcon,
                widgetData,
                featureFiles,
                activeGroupBy,
                setGroupBy,
                loading,
                setLoading,
                showLegend,
                setShowLegend
            }}
        >
            {children}
        </WidgetDataContext.Provider>
    )
}

export { WidgetDataContext }
export default withConfig(WidgetDataContextProvider)
