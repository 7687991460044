import React from 'react'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import { formatNumber } from './numberFormatting'

const transform = (node, index) => {
    if (node.name === 'a') {
        if (
            node.attribs.href.includes('http') ||
            node.attribs.href.includes('www') || node.attribs.href.includes('javascript')
        ) {
            return convertNodeToElement(node, index, transform)
        } else {
            return <Link className="internalLink" to={node.attribs.href}>{convertNodeToElement(node, index, transform)}</Link>
        }
    } else {
        return convertNodeToElement(node, index, transform)
    }
}

const formatValue = (value) => {
    if (typeof value !== 'undefined' && value !== null) {
        if (isNaN(value)) {
            if (dayjs(value).isValid()) {
                return dayjs(value).format('MM/DD/YYYY').toString()
            } else {
                return ReactHtmlParser(value, {transform})
            }
        } else {
            return formatNumber(value)
        }
    } else {
        return null
    }
}

//if it's not a valid date (i.e null) set the date to an absurdly high value so they get filtered to the bottom in no particular order https://stackoverflow.com/questions/60907412/how-to-sort-array-by-date-when-some-dates-are-null 
// https://stackoverflow.com/questions/28993107/momentjs-how-to-prevent-invalid-date
const formatDate = (value ) =>
    dayjs(value).isValid() ? dayjs(value).format('MM/DD/YYYY') : dayjs('1/1/1002').format('MM/DD/YYYY')

export default formatValue
export  { formatDate }
