import React, { useContext } from 'react'
import Particles from 'react-particles-js'
import { UserContext } from '../../wrappers/UserContext'
import particleConfig from './particleConfig'
import LandingPageNav from './LandingPageNav'

const LandingPageWrapper = ({children}) => {
    const { user } = useContext(UserContext)
    return (
        <>
             <Particles className={`particlesWrapper ${user ? '' : 'login'}`} params={particleConfig} />
             {user && <LandingPageNav />}
            <div className={`landingPageWrapper`}>
                <div className="landingPage">
                    <div className={`landingPageBanner`}>
                        <div className="landingPageBannerText is-size-1-desktop is-size-2-tablet is-size-3-mobile">
                            State of Oklahoma - Dashboard
                        </div>
                    </div>
                    <div className={`landingPageContent ${user ? '' : 'short'}`}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingPageWrapper
