import React, { useContext } from 'react'
import LandingPageWrapper from '../../elem/landing-page/LandingPageWrapper'
import { UserContext } from '../../wrappers/UserContext'
import withConfig from '../../wrappers/withConfig'
import LandingPageNav from '../../elem/landing-page/LandingPageNav'

const LoginPage = withConfig(({ config }) => {
    const { client, user } = useContext(UserContext)
    const { CONFIGURED_URLS } = config

    return (
        <div className="loginLandingPageWrapper">
            {user && <LandingPageNav />}
            <div className="section loginPageWrapper">
                <h2 className="applicationName">
                    State of Oklahoma - Dashboard
                </h2>
            </div>
            <div className="section">
                <div className="is-size-4 authenticationText">
                        <span
                            className="authenticationLink"
                            onClick={function () {
                                client.signinRedirect()
                            }}
                        >
                            Login
                        </span>
                        <a
                            className="authenticationLink"
                            target="_blank"
                            rel="opener"
                            href={CONFIGURED_URLS.newRegistration}
                        >
                            Register User
                        </a>
                </div>
            </div>
        </div>
    )
})

export default LoginPage
